import ApiService from '@/shared/services/api.service';

export class DecisionTargetService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/decision-targets', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/decision-targets?echo=true', { ...data } )
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/decision-targets/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/decision-targets/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete(param = {}) {
        const params = {
            params: {
                limit: 100,
            }
        };

        const response = await ApiService.query('/decision-targets', params);

        if (param?.search) {
            const result = response?.data?.data.filter(item => item.name.toLowerCase().startsWith(param.search));
            return result;
        }

        return response?.data?.data || [];
    }
}
