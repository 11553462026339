import decisionTargetFromStore from '@/modules/decision-target/store/decision-target-form-store';
import decisionTargetListStore from '@/modules/decision-target/store/decision-target-list-store';

export default {
    namespaced: true,

    modules: {
        form: decisionTargetFromStore,
        list: decisionTargetListStore,
    },
};
